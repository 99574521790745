@import "../App.scss";

.card {
    width: 100%;
    max-width: 430px !important;
    box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.1) !important;
    margin: 0 auto;

    @media(min-width: $tablet) {
        width: 430px;
        margin: 0;
    }
}

.content {
    text-align: start;
}

.title {
    overflow: hidden;
}

.card:hover {
    border: solid thin $link;
}
