@import "../App.scss";

.feedback-box {
  background-color: $purple-3;
  padding: 1rem;
  position: absolute;
  margin-top: 10px;
  width: 90vw;

  @media(min-width: $tablet) {
    width: 430px;
  }
}

.feedback-box::before {
  position: absolute;
  top: -10px;
  left: 0;
  content: ' ';
  border-bottom: 10px solid $purple-3;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
