@import '../App.scss';

.module-card {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  border: thin solid rgba(0, 0, 0, 0);
  flex-grow: 1;

  @media(min-width: $tablet) {
    margin-left: 3rem;
  }
}
