$primary: #483E87;
$link: #E88D21;

$purple-1: #e8e7f0;
$purple-2: #d1cfe1;
$purple-3: #bab7d2;
$purple-4: #8d86b4;
$purple-5: #766ea5;
$purple-6: #483e87;

button.is-success {
  background: linear-gradient(to right, $purple-5, $purple-4);
}

body,
html {
  min-height: 100%;
  margin: 0;
}

.App,
#root {
  height: 100%;
  min-height: 100vh;
}

html,
body,
button,
input,
select {
  font-family: Montserrat !important;
}

.App::before {
  position: fixed;
  width: 100vw;
  height: 100vh;
  content: '';
  background-color: $purple-1;
}

h1.logo {
  margin: 0 0 40px;
  text-align: center;
}

@import "../node_modules/bulma/bulma.sass";

.container {
  padding: 5rem 15px;
}

// TODO:
// This is relative to the back button in Question page
// Improve this
.icon:hover {
  cursor: pointer;
}
